import Nav from "../components/UI/Nav";
import Transition from "../components/UI/Transition";
import Lotus from "../components/Design/Lotus";
import Koi from "../components/Design/Koi"
import '../css/Contact.css'
import { Link } from "react-router-dom";
import Links from "../components/UI/Links";
import React, { useState } from "react";
import { motion } from "framer-motion";
import downAnim from "../utils/downAnim";
import upAnim from "../utils/upAnim";
export default function Contact() {
    const [isHovered, setHovered] = useState(false)
    const [isHovered2, setHovered2] = useState(false)

    return (
        <Transition>
            <div className="bg__wave">
                <img src="/assets/bg/wave.svg" alt="lake wave" />
            </div>

            <div className="container">
                <div className="bg__lotus">
                    <Koi />
                    <Lotus />
                </div>
                <div className="main__container">
                    <Nav />
                    <div className="contact__mainContent">
                        <motion.div
                            animate={downAnim(1.75)}
                            exit={upAnim(0.2)}
                            className="main__title">
                            <h1 >Cheers</h1>
                            <h3 className="contact__slogan">Hello, I'm Rachel.<br /> Ready for a creative journey?</h3>
                        </motion.div>
                        <motion.div
                            animate={downAnim(2)}
                            className="contact__list">
                            <Links />
                        </motion.div>

                        <div className="contact__btn">
                            <Link to="/">
                                <button
                                    className="contact__btn1"
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}>
                                    <motion.p
                                        animate={{ x: isHovered ? '-100%' : 0, transition: { ease: 'easeInOut', duration: 0.75 } }}
                                    >← </motion.p>
                                    Selected Projects
                                </button>
                            </Link>
                            <Link to="/designs">
                                <button
                                    className="contact__btn2"
                                    onMouseEnter={() => setHovered2(true)}
                                    onMouseLeave={() => setHovered2(false)}>
                                    Recent Designs
                                    <motion.p
                                        animate={{ x: isHovered2 ? '100%' : 0, transition: { ease: 'easeInOut', duration: 0.75 } }}
                                    >→</motion.p>
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </Transition>

    )
}