


export default function PictokComments() {
    return (
        <div className="pictok__research__comments">
            <div style={{
                width: '16em',
                marginLeft: '5vw',
                backgroundColor: 'var( --color--pictok--lightblue)',
                padding: '0 1.5em 0 1.4em',
                borderRadius: '2em',
                fontSize: '0.7em',
                fontWeight: '600',
                opacity: '0.4'
            }}>
                <div style={{
                    width: '0',
                    height: '0',
                    borderLeft: '1.5em solid transparent',
                    borderRight: '1.5em solid  transparent',
                    borderTop: '2em solid var(--color--pictok--lightblue',
                    position: 'absolute',
                    marginTop: '2.75em'
                }}><span></span></div>
                <p>"I want to post Instagram stories."</p>
            </div>

            <div
                style={{
                    marginLeft: '10vw',
                    width: '12em',
                    backgroundColor: 'var( --color--pictok--lightblue)',
                    padding: '0 1.5em 0 1.5em',
                    borderRadius: '2em',
                    fontSize: '0.7em',
                    fontWeight: '600',
                    opacity: '0.65'
                }}>
                <div style={{
                    width: '0',
                    height: '0',
                    borderLeft: '1.5em solid transparent',
                    borderRight: '1.5em solid  transparent',
                    borderTop: '2em solid var(--color--pictok--lightblue',
                    position: 'absolute',
                    marginTop: '2.5em',
                    marginLeft: '8em'
                }}><span></span></div>
                <p>"Really hard to navigate."</p>
            </div>
            <div style={{
                width: '17em',
                backgroundColor: 'var( --color--pictok--lightblue)',
                padding: '0 1.5em 0 1.4em',
                borderRadius: '2em',
                fontSize: '0.7em',
                fontWeight: '600',
                opacity: '0.5'
            }}>
                <div style={{
                    width: '0',
                    height: '0',
                    borderLeft: '1.5em solid transparent',
                    borderRight: '1.5em solid  transparent',
                    borderTop: '2em solid var(--color--pictok--lightblue',
                    position: 'absolute',
                    marginTop: '2.5em',
                    marginLeft: '7em'
                }}><span></span></div>
                <p>"Alternate text doesn't make sense."</p>
            </div>

        </div>

    )
}