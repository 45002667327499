import React from "react";


export default function PjCard05() {

    return (
        <div>
            <img
                className="project__hide"
                style={{
                    position: 'absolute',
                    width: '8em',
                    marginLeft: '-4.5em',
                    marginTop: '24em',
                    zIndex:1,
                }}
                src="/assets/projects/gg/greenie.png" alt="green generation mascot" />
            <div>
                <video
                    width="230em"
                    height="auto"
                    style={{
                        borderRadius:'2em',
                        border:'0.2em solid var(--color--gg--dark)',
                        position:'relative'
                    }}
                    autoPlay
                    muted
                    loop
                >
                    <source src="/assets/projects/gg/gg-intro.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

        </div>


    );
}
