import ProjectData from '../../../data/ProjectData.json';
import React, { useEffect, useState } from 'react';
import renderCard from "../../../utils/renderCard";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function PjCard() {
    const [caseStudies, setCaseStudies] = useState([]);


    useEffect(() => {
        setCaseStudies(ProjectData.caseStudy);
    }, [])

    return (
        <div className='pjcard__container'>
            {
                caseStudies.map((item, i) => (
                    <motion.div
                        initial={{ opacity: 0.3 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: false, opacity: 0.5 }}
                        key={i}
                        className='pjcard__grid'
                        style={{ backgroundColor: `${item.color}`, color: `${item.fontColor}` }}>
                        <div className='pjcard__context project__hide'>
                            <div className='pjcard__tags'>
                                <p>#{item.tag1}</p>
                                <p>#{item.tag2}</p>
                            </div>

                            <p>{item.summary}</p>
                        </div>

                        <div className='pjcard__cardContainer'>
                            <motion.h1
                               className={`project__hide pjcard__name1__${item.id}`}>{item.name1}</motion.h1>
                            <motion.h1
                                className={`project__hide pjcard__name2__${item.id}`}>{item.name2}</motion.h1>
                            <Link to={item.title}> {renderCard(item.card)} </Link>

                        </div>
                        <div className='pjcard__btn'>
                            <Link to={item.title}>
                                <button style={{border:`0.15em solid ${item.fontColor}`, color:`${item.fontColor}`}}>Explore ↗</button>
                            </Link>
                            <h3 className='project__hide'>0{item.id}</h3>
                        </div>
                        <div className='pjcard__context__screen'>
                            <div className='pjcard__tags'>
                                <p>{item.title.toUpperCase()}</p>
                                <p>#{item.tag1}</p>
                                <p>#{item.tag2}</p>
                            </div>

                            <p>{item.summary}</p>
                        </div>
                    </motion.div>
                ))
            }
        </div>



    )
}