
import PictokComments from "./PictokComments"

export default function PictokGoal() {
    return (
        <div className="pictok__mainContainer">
            <div className="pictok__mainTitle">
                <h2>Research</h2>
            </div>
            <div className="pictok__mainContent">
                <p className="pictok__summary__content">In the summer of 2023, the class embraced the challenge of developing a web/mobile solution within a four-month timeframe, with a focus on utilizing Artificial Intelligence to benefit disadvantaged communities. The PicTok team opted to focus on visually impaired communities, researching their interations on photo-related social platforms.
                </p>
                <div className="pictok__research">
                    <PictokComments />
                    <div style={{ position: 'relative', zIndex: '10' }}>
                        <h3>"The existing social platforms lack sufficient accessibility features."</h3>

                        <p>Through the research of multiple scientific papers, the PicTok team has noticed a significant interests among VIPs, especially among young VIP teenagers, in sharing, viewing and posting photos with friends on social platforms. </p>
                        <p>Despite the availability of mobile and app accessibility tools, VIPs are still encountering issues, such as <span>excessive content in navigation</span>, <span>missing image alternative text</span>, and <span>inaccurate text-to-speech generation</span>.</p>
                    </div>

                </div>

            </div>

        </div>

    )
}