import { Link } from "react-router-dom"
import PictokBrochure from "./PictokBrochure"

export default function PictokFinal() {
    return (
        <div className="pictok__final">
            <img
                className="pictok__outcome__bg"
                src="/assets/projects/pictok/pictok-mockup-trans.png" alt="pictok background"
            />

            <div>

                <div className="pictok__outcome">
                    <div className="pictok__figma pictok__app__content">
                        <h3>App Preview</h3>
                        <p>Our App is live now!</p>
                        <Link
                            className="pictok__app__link"
                            to="https://pictok.vercel.app" target="_blank" rel="noopener noreferrer">
                            <button>
                                <p><strong>Check Out the App  ↗</strong></p></button>
                        </Link>
                    </div>
                </div>

                <img
                    className="pictok__outcome__pages"
                    src="/assets/projects/pictok/pictok-show.png" alt="pictok pages"
                />
                <PictokBrochure />



            </div>
        </div>

    )
}