import React, { useRef, useEffect } from 'react';

const SingleVideo = ({ src1, links }) => {


  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (videoRef.current && videoRef.current.paused) {
            videoRef.current.play().catch(error => {
              console.error('Error playing video:', error);
            });
          }
        } else {
          if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='gallery__box gallery__singleVideo'>

      <video
        ref={videoRef}
        width="100%"
        height="auto"
        autoPlay
        muted
        loop
      >
        <source src={src1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    
    </div>
  );
};

export default SingleVideo;
