
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function SingleImg({src1, alt1}) {


    return (

        <div className="gallery__box gallery__singleImg">
           
                <LazyLoadImage src={src1} alt={alt1} effect="blur"/>
            
        </div>

    );
}
