import Nav from "../../components/UI/Nav"
import PjCard01 from "../../components/Projects/PjCard/PjCard01";
import PictokIntro from "../../components/Projects/Pictok/PictokIntro";
import PictokGoal from "../../components/Projects/Pictok/PictokGoal";
import PictokInfo from "../../components/Projects/Pictok/PictokInfo";
import PictokStyle from "../../components/Projects/Pictok/PictokStyle";
import PictokSummary from "../../components/Projects/Pictok/PictokSummary";
import PictokChallenge from "../../components/Projects/Pictok/PictokChallenge";
import PictokFinal from "../../components/Projects/Pictok/PictokFinal";
import Transition from "../../components/UI/Transition";
import ProjectFooter from "../../components/UI/Footer/ProjectFooter";
import ArrowUp from "../../components/Creations/ArrowUp";

import { useEffect } from "react";

export default function Pictok() {

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);
    return (
        <Transition >
            <div
                style={{
                    backgroundColor: '#f8fbfd',

                }}
                className="main__container re-padding">
                <div className="menu__fix">
                    <Nav />
                </div>


                <div className="pictok__container">
                    <section className="pictok__topContainer topContainer">
                        <PjCard01 />
                        <PictokIntro />
                    </section>
                    <section className="pictok__mainContainer">

                        <div>
                            <PictokSummary />
                            <PictokGoal />
                            <PictokInfo />
                            <PictokStyle />
                            <PictokChallenge />
                            <div className="pictok__mainContainer">
                                <div className="pictok__mainTitle">
                                    <h2>Launch</h2>
                                </div>
                                <div className="pictok__mainContent">
                                    <p>
                                    After four months of dedicated effort, our PicTok app is finally ready! Let's explore it together.</p>

                                </div>
                            </div>
                                <PictokFinal />

                            </div>

                    </section>

                </div>
            </div>
            <ArrowUp />
            <ProjectFooter projectName="pictok" />

        </Transition>
    )
}