import React from "react"

export default function PictokIntro() {
    return (
        <div className="pictokIntro__container">
            <div className="pictokIntro__title">
                <h1>An Accessible Solution for the Visually Impaired</h1>
                <div className="pictokIntro__name">
                    <h2 className="show">PicTok</h2>
                    <img className="pictokIntro__braille hide" src="/assets/projects/pictok/pictok-braille-blue.png" alt="braille for PicTok" />
                </div>
                <div className="pictokIntro__logo">
                    <img src="/assets/projects/pictok/pictok-logo-ani.gif" alt="animated pictok logo" />
                </div>
            </div>

            <div className="pictokIntro__slogan">
                <p>
                    <strong>PicTok</strong> serves the purpose of reconnecting our <strong>VIPs</strong> (Visually Impaired People) to photo-sharing experiences.

                </p>
            </div>
        </div>
    )
}