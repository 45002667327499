

export default function PjCard03() {


    return (
            <div>
                <img 
                className="project__hide__s"
                style={{
                    position:'absolute',
                    width:'5em',
                    zIndex:'1',
                    transform:'rotate(-50deg)',
                    marginTop:'21.5em',
                    marginLeft:'-0.5em'
                }}
                src="/assets/projects/infj/infj-leaf.png" alt="infj leaf decoration"/>
                  <img 
                  className="project__hide__s"
                style={{
                    position:'absolute',
                    width:'5em',
                    zIndex:'1',
                    transform:'rotate(130deg) ',
                    marginTop:'1.15em',
                    marginLeft:'11.25em'
                }}
                src="/assets/projects/infj/infj-leaf.png" alt="infj leaf decoration"/>
                <img src="/assets/projects/infj/infj-tablet.png" alt="infj website mockup"
                    loading="lazy"
                    style={{
                        position:'relative',
                        zIndex:'10',
                        width: "16em",
                        paddingTop:'2em'
                    }} />
            </div>
    );
}
