import { Routes, Route, useLocation } from "react-router-dom";

import Creations from "./web/Creations";
import Projects from "./web/Projects";
import Contact from "./web/Contact";
import Pictok from "./web/Projects/Pictok";

import CC from "./web/Projects/CC";
import MB from "./web/Projects/MB";
import INFJ from "./web/Projects/INFJ";
import GG from "./web/Projects/GG";

import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  return (
    <AnimatePresence >

      <Routes location={location} key={location.pathname}>
        <Route index element={<Projects />} />
        <Route
          path="/pictok"
          element={<Pictok />}
        />
        <Route
          path="/creep culture"
          element={<CC />}
        />
        <Route
          path="/infj secret place"
          element={<INFJ />}
        />
        <Route
          path="/memory box"
          element={<MB />}
        />
        <Route
          path="/green generation"
          element={<GG />}
        />
        <Route path="/designs" element={<Creations />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

    </AnimatePresence>


  );
}

export default App;
