import {motion} from "framer-motion"

export default function Koi() {
    return (
        <div>
            <img className="contact__koi" src="/assets/bg/koi.svg" alt="koi fish" />
            <motion.div 

                animate={{x:-10}}
                transition={{duration:7, repeat:Infinity, repeatType:'reverse'}}
            className="koi__balls__container">
                <motion.div 
                animate={{opacity:[0,1,0]}}
                transition={{duration:3, repeat:Infinity}}
                className="koi__balls"><span></span></motion.div>
                 <motion.div 
                 
                animate={{y:'-15vw', x:-50, opacity:[0,1,0]}}
                transition={{duration:3, repeat:Infinity, delay:1}}
                className="koi__balls"><span></span></motion.div>
                 <motion.div 
                animate={{y:'-30vw', x:-80,opacity:[0,1,0]}}
                transition={{duration:3, repeat:Infinity,  delay:2}}
                className="koi__balls"><span></span></motion.div>
            </motion.div>
        </div>

    )
}