import {useState} from "react";
export const useAudioOverlay = () => {
    const [isOverlayHidden, setOverlayHidden] = useState(false);

    const handleOverlayClick = () => {
        const audio = document.getElementById('pictok-audio');
        audio.play();
        setOverlayHidden(true);
    };

    const handleAudioEnded = () => {
        setOverlayHidden(false);
    };

    return {
        isOverlayHidden,
        handleOverlayClick,
        handleAudioEnded,
    };
};