import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Hambuger from "./Hambuger";
import MenuBtn from "./MenuBtn";
import NavItem from "./NavItem";

import "./nav.css";

const Nav = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const classNameFunc = ({ isActive }) => (isActive ? "active" : "");

    return (
        <nav
            className="nav__container">
            <div className="nav__name">
                <NavLink to="/"> <h3>Rachel X.</h3></NavLink>

            </div>
            <NavItem classNameFunc={classNameFunc} />
            <Hambuger classNameFunc={classNameFunc}
                isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
            <MenuBtn
                isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />


        </nav>
    )
}

export default Nav;