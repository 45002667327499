import Links from "../Links"
import { Link } from "react-router-dom"
export default function Footer() {
    return (
        <div className="page__footer">

            <div className="page__name">
                <Link to="/">  <h3>Rachel X.</h3></Link>

            </div>
            <div className="page__links">
                <Links />
            </div>


        </div>
    )
}