import {motion} from 'framer-motion';

export default function Fish() {

    return (
        <motion.div
        style={{
            position:'absolute',
            top:'50%',
            left:'20%',
        }}
        animate={{ rotate:[40,50], opacity: [0,1,0], x: [-200,850], y: [-500,200] }}
        transition={{duration:15,ease:'linear', repeat:Infinity,repeatDelay:3}}
            >
            <img
                style={{
                    position: 'absolute',
                    width: '1.85em',
                    marginTop:'1.75em',
                    marginLeft:'2em',
                    opacity: '0.5'
                }}
                src="/assets/bg/fish.svg" alt="fish" />
            <img
                style={{
                    position: 'absolute',
                    width: '1.25em',
                    marginTop:'2.75em',
                    marginLeft:'-0.75em',
                    opacity: '0.5'
                }}
                src="/assets/bg/fish.svg" alt="fish" />
            <img
                style={{
                    position: 'absolute',
                    width: '1.75em',
                    marginTop:'2.35em',
                    marginLeft:'4em',
                    opacity: '0.5'
                }}
                src="/assets/bg/fish.svg" alt="fish" />
            <img
                style={{
                    position: 'absolute',
                    width: '1.5em',
                    marginTop:'3em',
                    marginLeft:'0.5em',
                    opacity: '0.5'
                }}
                src="/assets/bg/fish.svg" alt="fish" />
            <img
                style={{
                    position: 'absolute',
                    width: '2.5em',
                    marginTop:'0em',
                    marginLeft:'4em',
                    opacity: '0.5'
                }}
                src="/assets/bg/fish.svg" alt="fish" />
        </motion.div>
    )
}