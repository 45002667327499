
import { useAudioOverlay } from "../../../../utils/handleOverlayClick";
import { motion } from "framer-motion"
export default function PictokSummary() {

    const { isOverlayHidden, handleOverlayClick, handleAudioEnded } = useAudioOverlay();

    return (
        <div className="pictok__mainContainer">
            <div className="pictok__mainTitle">
                <h2 >Overview</h2>
            </div>
            <div className="pictok__mainContent">
                <p className="pictok__summary__content">PicTok stands out as a platform primarily for teenagers and young adult VIPs, implementing inclusive AI features that identifies objects within images. It generates stories based on these objects and converts them into sound, offering a unique and innovative experience.</p>
                <div className="pictok__summary">
                    <div className="pictok__features">
                        <div className="pictok__features__item">
                            <img
                                style={{
                                    width: '4em',
                                    height: '4em',
                                    padding: '1em',
                                    backgroundColor: 'rgba(250,250,250,1)',
                                    borderRadius: '2em'
                                }}
                                src="/assets/projects/pictok/pictok-btn.png" alt="pictok sample btn" />
                            <div>
                                <p>
                                    <strong>Simple Design</strong>
                                </p>
                                <p>The interface features minimized buttons, with interactions primarily conducted through text and audio.</p>
                            </div>
                        </div>
                        <div className="pictok__features__item">
                            <img
                                style={{
                                    height: '6.5em',
                                    backgroundColor: 'rgba(250,250,250,1)',
                                    borderRadius: '2em'
                                }}
                                src="/assets/projects/pictok/pictok-listen.png" alt="pictok sample btn" />
                            <div>
                                <p><strong>Seamless Experience</strong></p>
                                <p>The core of PicTok is to ensure a straight-forward process: click, listen and swipe for more pictures! </p>
                            </div>

                        </div>
                    </div>
                    <div>
                        <img
                            style={{
                                width: "10em",
                                borderRadius: "1em",
                                border: '0.2em solid var(--color--pictok--blue)',
                            }}
                            src="/assets/projects/pictok/pictok-inbox.png"
                            alt="pictok inbox mockup" />

                        {(!isOverlayHidden && (
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.9 }}
                                exit={{ opacity: 0 }}
                                style={{
                                    width: "10em",
                                    height: '18.24em',
                                    position: "absolute",
                                    marginLeft: '-10.2em',
                                    marginTop: '3.67em',
                                    borderRadius: "0 0 0.8em 0.8em",
                                    opacity: '0.9',
                                    cursor: "pointer"
                                }}
                                src="/assets/projects/pictok/pictok-overlay.png"
                                alt="pictok inbox overlay"
                                onClick={handleOverlayClick}
                            />
                        ))}

                        {(!isOverlayHidden && (
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.9 }}
                                exit={{ opacity: 0 }}
                                style={{
                                    width: '3em',
                                    position: "absolute",
                                    marginLeft: '-6.5em',
                                    marginTop: '11em',
                                    cursor: "pointer"
                                }}
                                src="/assets/projects/pictok/pictok-tap.gif"
                                alt="animated tap icon"
                                onClick={handleOverlayClick}
                            />
                        ))}
                        <audio id="pictok-audio" onEnded={handleAudioEnded}><source src="/assets/projects/pictok/pictok-voice.mp3" type="audio/mp3" /></audio>

                    </div>
                </div>
              
                <div className="pictok__subContent pictok__role__container">

                    <h3>My Role</h3>
                    <img src="/assets/projects/pictok/pictok-r.png" alt="rachel" width={100} />
                    <div className="pictok__role">
                        <p># UI/UX</p>
                        <p># Interaction</p>
                        <p># Brochure</p>
                    </div>
                </div>
            </div>

        </div>


    )
}