import "../css/Design.css";
import Nav from "../components/UI/Nav";
import React, { useEffect } from 'react';
import Duo12 from "../components/Creations/Gallery/Duo12";
import SingleVideo from "../components/Creations/Gallery/SingleVideo"
import Duo11 from "../components/Creations/Gallery/Duo11";
import SingleImg from "../components/Creations/Gallery/SingleImg";
import ArrowUp from "../components/Creations/ArrowUp";
import Transition from "../components/UI/Transition";
import { motion } from "framer-motion";
import Footer from "../components/UI/Footer";
import downAnim from "../utils/downAnim";
import upAnim from "../utils/upAnim";
import Trio from "../components/Creations/Gallery/Trio"
export default function Creations() {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);

    return (
        <Transition >
            <div className="bg__wave">
                <img src="/assets/bg/wave.svg" alt="lake wave" />
            </div>
            <div className="main__container">
                <Nav />

                <motion.div
                    className="gallery__container">

                    <motion.h1
                        animate={downAnim(1.75)}
                        exit={upAnim(0.2)}
                    >The Canvas of Imagination</motion.h1>

                    <Trio
                        src1="/assets/design/illustration/infj-1.png"
                        src2="/assets/design/illustration/infj-3.png"
                        src3="/assets/design/illustration/infj-2.png"
                        alt1="infj illustrator poster 1"
                        alt2="infj illustrator poster 2"
                        alt3="infj illustrator poster 3"
                    />
                    
                    <Trio
                        src1="/assets/design/illustration/invitation-2.png"
                        src2="/assets/design/illustration/invitation-3.png"
                        src3="/assets/design/illustration/invitation-1.png"
                        alt1="wedding red pocket"
                        alt2="wedding invitation"
                        alt3="wedding website mockup"
                    />

                    <Trio
                        src1="/assets/design/illustration/dayNight-3.png"
                        src2="/assets/design/illustration/dayNight-1.png"
                        src3="/assets/design/illustration/dayNight-2.png"
                        alt1="day and night illustration 1"
                        alt2="day and night illustration 2"
                        alt3="day and night illustration 3"
                    />


                    <Duo11
                        src1="/assets/design/poster/warrior.png"
                        src2="/assets/design/poster/alice.png"

                        alt1="warrior movie poster"
                        alt2="alice in wonderland movie poster"

                    />
                    <Duo11
                        src1="/assets/design/illustration/musicStore.png"
                        src2="/assets/design/illustration/change.png"

                        alt1="sample illustrator poster "
                        alt2="sample illustrator poster"

                    />
                    <Duo12
                        src1="/assets/design/illustration/treeAd-1.png"
                        src2="/assets/design/illustration/treeAd-2.png"
                        src3="/assets/design/illustration/treeAd-3.png"
                        alt1="east tree company poster 1"
                        alt2="east tree company poster 1"
                        alt3="east tree company poster 1"
                    />
                    <Duo12
                        src1="/assets/design/poster/projectX-1.png"
                        src2="/assets/design/poster/projectX-3.png"
                        src3="/assets/design/poster/projectX-2.png"
                        alt1="game projert x poster"
                        alt2="game projert x game over screen"
                        alt3="game projert x box"
                    />
                    <SingleImg src1="/assets/design/magazine/magazine.png" alt="futuaistic magazine" />

                    <SingleVideo src1="/assets/design/motionGraphics/busmove.mp4" />

                    <SingleVideo src1="/assets/design/motionGraphics/garden.mp4" />
                    <SingleVideo src1="/assets/projects/pictok/pictok-ad.mp4" />

                </motion.div>
                <ArrowUp />
            </div>
            <Footer />
        </Transition>
    );
}
