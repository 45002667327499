import React from "react";


export default function PjCard04() {

    return (
        <div>
            <img
            className="project__hide"
                style={{
                    position: 'absolute',
                    width: '12em',
                    opacity: '0.5',
                    marginLeft: '17em',
                    marginTop: '12em',
                    transform:'rotate(-20deg)'
                }}
                src="/assets/projects/mb/mb-broken.png" alt="broken decoration" />
           
            <img src="/assets/projects/mb/mb-tv.png"
                alt="memory box game"
                className="project__img__resize"
                style={{
                    width: "22em",
                    marginTop: '2em',
                    position:'relative',
                    zIndex:'10'
                }} />
           
        </div>


    );
}
