import React from "react";
import { motion } from "framer-motion";
import "./PictokToggle.css";

export default function PictokToggle({ isDark, toggleSwitch }) {

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    return (
        <div
            style={{
                marginBottom: '2em'
            }}>
            <div
                style={{
                    background: isDark ? "linear-gradient(180deg, #f8f5f1a8 20%, #89a4e2bd 100%)"
                        : "linear-gradient(180deg, #feffc993 19%, #c5e1f598 100%)",
                }}
                className={`pictok__switch ${isDark ? "pictok__switch__dark" : ""}`}
                onClick={toggleSwitch}>
                <motion.div
                    style={{
                        backgroundColor: isDark ? "#6C96F5" : "#199DFC",
                    }}
                    className="pictok__handle"
                    layout transition={spring}>
                    <motion.div
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1 }}>
                        {isDark ?
                            <img src="/assets/projects/pictok/pictok-sun.svg" alt="icon" /> :
                            <img src="/assets/projects/pictok/pictok-moon.svg" alt="icon" />}
                    </motion.div>
                </motion.div>
            </div>
            {!isDark && (
                < motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    onClick={toggleSwitch}
                    className="pictok__color__click"><strong>Click</strong></motion.p>
            )}
            {
                !isDark && (
                    <motion.img
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, }}
                        transition={{ duration: 0.5 }}
                        onClick={toggleSwitch}
                        style={{
                            width: '8em',
                            position: 'absolute',
                            marginTop: '-2.5em',
                            marginLeft: '7em',
                        }}
                        src="/assets/projects/pictok/pictok-arrow.png" alt="arrow" />
                )
            }

        </div >

    );
}

