import React from "react";
import PictokColor from "./PictokColor";
import { Link } from "react-router-dom";
export default function PictokStyle() {

    return (
        <div className="pictok__mainContainer">
            <div className="pictok__mainTitle">
                <h2>Design</h2>
            </div>
            <div className="pictok__mainContent">
                <p>
                    The style guide was created simultaneously with the wireframe. The chosen font, <strong className="pictok__jost">"Jost"</strong>, was selected as a copyright-free alternative to the commonly used accessible font, "Verdana".
                    The color choices adhere to color contrast rules, aligning with the A11Y guide (the Accessibility Guide) and ensuring color blindness friendliness.</p>

                <div className="pictok__subContent">
                    <div>
                        <h3>Color and Icons in Design</h3>
                        <p> Blue is considered one of the safest hues for colorblind individuals.
                            Yellowish-Orange serves as a contrasting color to the blue, ensuring high contrast and easy distinction between buttons. </p>
                        <p>The option of dark mode provides flexibility for VIPs with dyslexia or light sensitivities.
                            In dark mode, color choices are aligned with the regular palette, featuring a subtle violet-blue and bright yellow for enhanced contrast against the dark background.
                        </p>
                        <p>Icons and alteration text also provides aid for text and enhancing the overall user experience.</p>
                    </div>

                </div>
                <PictokColor />
                <div className="pictok__subContent">
                    <h3>Atomic Design System</h3>
                    <p>PicTok followed the Atomic design principles, breaking down all pages into the smallest components known as "atoms". The design system enabled flexible changes in colors and a structured approach to facilitate Figma wireframing and prototyping.</p>
                    <p>Here is an example of buttons on the home page, illustrating the journey from atomic components to the final deliverable.</p>
                </div>

                <div className="pictok__figma">
                <img style={{ width: '100%' }} src="/assets/projects/pictok/pictok-atomic.png" alt="pictok atomic design example" />
                    <Link to="https://www.figma.com/file/91NkyEZa5ydc3c4FRXjcOo/Pic-Tok-%3A)?type=design&node-id=1394%3A5887&mode=design&t=P3WYGdKRCNOETIF9-1" target="_blank" rel="noopener noreferrer">
                        <button><p>
                            <strong>Check out the design on Figma  ↗</strong></p></button>
                    </Link>
                </div>
            </div>

        </div>

    )
}