
import { motion } from "framer-motion"
import downAnim from "../../../utils/downAnim";
import upAnim from "../../../utils/upAnim";

export default function FixedBox({ opacity, targetRef, content1, content2 }) {
    return (

        <motion.section
            className="main__container__top"
            style={{ opacity }}
            ref={targetRef}>
            <div className="main__container__motionChild">
                <motion.div

                    animate={downAnim(1.75)}
                    exit={upAnim(0.2)}
                    className="main__title">
                    <h1>{content1}</h1>
                </motion.div>

                <motion.div
                    animate={downAnim(2)}
                    exit={upAnim(0.4)}
                    className="main__arrow">
                    <motion.div
                        animate={{ x: 248 }}
                        transition={{ delay: 2, ease: 'linear', duration: 2.5 }}
                        className="main__arrowColor">
                        <span></span>
                    </motion.div>
                    <div className="main__arrow__content">
                        <p>{content2}</p>
                        < motion.p
                            animate={{ y: [0, 10, -10, 0], x: [0, -10, 10, 0], scale: [1, 0, 0, 1] }}
                            transition={{ delay: 5, repeat: Infinity, ease: 'linear', duration: 1.5, repeatDelay: 1 }}
                        > ↙</motion.p>
                    </div>


                </motion.div>
            </div>

        </motion.section>


    );
}
