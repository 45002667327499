
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


export default function PictokBrochure() {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0)

    return (
        <div className="pictok__marketing">
            <div className="pictok__marketing__title">
                <h3>Marketing Collateral</h3>
                <p>Fun and Eye-catching</p>

            </div>
            <div className="pictok__brochure__container">
                <div className="pictok__promotional__item">
                    <div>
                        <img
                            onClick={() => { setOpen(true); setIndex(0); }}
                            className="pictok__brochure"
                            src="/assets/projects/pictok/pictok-brochure-outside.png" alt="pictok brochure front"
                        />
                    </div>
                </div>
                <div className="pictok__promotional__item">
                    <div>
                        <img
                            className="pictok__brochure"
                            onClick={() => { setOpen(true); setIndex(1); }}
                            src="/assets/projects/pictok/pictok-brochure-inside.png" alt="pictok brochure back" width={500} />
                    </div>
                </div>

                <Lightbox
                    styles={{
                        container: { backgroundColor: "rgba(25,157, 252, .8)" }
                    }}
                    open={open}
                    index={index}
                    close={() => setOpen(false)}
                    slides={[
                        { src: "/assets/projects/pictok/pictok-brochure-front.png" },
                        { src: "/assets/projects/pictok/pictok-brochure-back.png" },
                    ]}
                />
            </div>
        </div>


    )
}