
export default function PjCard03() {

    return (
        <div>
            <img
                className="project__hide"
                style={{
                    position: 'absolute',
                    width: '2.5em',
                    marginTop: '1em',
                    marginLeft: '25.5em'
                }}
                src="/assets/projects/cc/cc-logo.png" alt="creep culture logo" />
            <img
                className="project__hide__s"
                style={{
                    position: 'absolute',
                    width: '5em',
                    opacity: '0.5',
                    marginLeft: '17.5em',
                    marginTop: '19em'
                }}
                src="/assets/projects/cc/cc-house1.png" alt="house decoration" />
            <img
                className="project__hide__s"
                style={{
                    position: 'absolute',
                    width: '2em',
                    opacity: '0.5',
                    transform: 'scaleX(-1)',
                    marginLeft: '22em',
                    marginTop: '17em',
                }}
                src="/assets/projects/cc/cc-bat.png" alt="bat decoration" />
            <img src="/assets/projects/cc/cc-three.png" alt="creep culture kombucha mockup"
                loading="lazy"
                className="project__img__resize"
                style={{
                    width: "20em",
                    paddingTop: '2em',
                    position: 'relative',
                    zIndex: '10'
                }} />

        </div>
    );
}
