import React from "react";
import { motion } from "framer-motion"


const MenuBtn = ({ isMenuActive, setIsMenuActive }) => {

    return (

        <div
        style={{
            position:isMenuActive?'fixed':'absolute',
            right:isMenuActive?'var(--margin--normal)':0
        }}
            onClick={() => setIsMenuActive(!isMenuActive)}
            className="nav__btn__container hambuger">
            <motion.div
                className="nav__btn"
                animate={{ left: isMenuActive ? "-100%" : "0" }}
                transition={{ ease: 'easeInOut', duration: 1 }}
            >
                <div className="nav__btn1">
                    <p>Menu</p>
                </div>
                <motion.div
                    style={{
                        backgroundColor: isMenuActive ? "rgba(250, 250, 250, 0.25" : "var(--color--darkgreen)"
                    }}
                    className="nav__btn2">
                    <p>Close</p>
                </motion.div>


            </motion.div>
        </div>
    )
}

export default MenuBtn;