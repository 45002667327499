import { motion } from "framer-motion";
import anim from "../../../../utils/anim";
export default function TransitionSvg({ width, height }) {


    const targetPath = `
        M0 300
        Q${width / 2} 150 ${width} 300
        L${width} ${height + 300}
        Q${width / 2} ${height + 450} 0 ${height + 300}
        L0 300
  `;

    const initialPath = `
         M0 0
        Q${width / 2 }  300 ${width} 0
        L${width} ${height + 300}
        Q${width / 2} ${height + 450} 0 ${height + 300}
        L0 300
    `;

    const slide = {
        initial: {
            top: "-300px",
          
        },
        enter: {
            top: '100vh',
            transition: {
                delay:1.25,
                duration:1.5,
                ease: "easeInOut",
            },
        },
        exit: {
            top: '-300px',
            transition: {
                delay: 0,
                duration: 1,
                ease: "easeInOut",
            },
        }
    };

    const curve = {
        initial: {
            d: initialPath
        },
        enter: {
            d: targetPath,
            transition: {
                delay: 1.25,
                duration: 1.75,
                ease: "easeInOut",
            },
        }
    }

    return (
        <>
            <motion.svg
                {...anim(slide)}
                className="transition__svg"
            >
                <motion.path
                    {...anim(curve)}
                    d={initialPath}
                ></motion.path>
            </motion.svg>



        </>

    );
}