
import { Outlet } from "react-router-dom";
import PjCard from "../components/Projects/PjCard";
import React, { useRef } from 'react';
import { useScroll, useTransform, motion } from "framer-motion";
import FixedBox from "../components/UI/FixedBox";
import Fish from "../components/Design/Fish";
import Lotus from "../components/Design/Lotus";
import "../css/Projects.css";
import Transition from "../components/UI/Transition";
import Footer from "../components/UI/Footer";
import ArrowUp from "../components/Creations/ArrowUp";
import Nav from "../components/UI/Nav";
export default function Projects() {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["end end", "end start"],
    });

    const opacity = useTransform(scrollYProgress, [0.4, 0.8], [1, 0]);
    const scale = useTransform(scrollYProgress, [0.2, 1], [1, 1.2])
    const y = useTransform(scrollYProgress, [0.2, 0.5], [1, -120])
    return (
        <Transition >
            <div className="bg__lotus">
                <motion.div
                    style={{ scale, y }}>
                    <Lotus />
                </motion.div>
                <Fish />
            </div>
            <div className="bg__wave">
                <img src="/assets/bg/wave.svg" alt="lake wave" />
            </div>
            <div className="main__container">
                <Nav />
                <FixedBox targetRef={targetRef} opacity={opacity} content1="Dream, Create, Bring" content2="Scroll To View Recent Projects" />
                <PjCard />
                <Outlet />
            </div>
            <ArrowUp />
            <Footer />
        </Transition>
    );
}
