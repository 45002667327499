import { Link } from "react-router-dom"
import ProjectData from '../../../../data/ProjectData.json';
import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import "../Footer.css"

export default function ProjectFooter({ projectName }) {

    const [caseStudies, setCaseStudies] = useState([]);
    const [isHovered, setHovered] = useState(false)
    const [isHovered2, setHovered2] = useState(false)

    useEffect(() => {
        setCaseStudies(ProjectData.caseStudy);
    }, [])

    const currentIndex = caseStudies.findIndex(item => item.title === projectName);
    const prevIndex = (currentIndex - 1 + caseStudies.length) % caseStudies.length;
    const nextIndex = (currentIndex + 1) % caseStudies.length;
    if (caseStudies.length === 0) {
        return null;
    }
    return (
        <div 
        style={{backgroundColor: `${caseStudies[currentIndex].background}`}}
        className="project__footer__container">
            <Link to={`/${caseStudies[prevIndex].title}`}>
                <button
                className="project__footer__button1"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{
                        backgroundColor: `${caseStudies[prevIndex].color}`,
                        color: `${caseStudies[prevIndex].fontColor}`
                    }}>

                    <motion.p
                    className="project__arrow"
                        animate={{ x: isHovered ? '-100%' : 0, transition: { ease: 'easeInOut', duration: 0.75 } }}
                    >← </motion.p>{caseStudies[prevIndex].title.toUpperCase()}
                </button>
            </Link>
            <Link to={`/${caseStudies[nextIndex].title}`}>
                <button
                 className="project__footer__button2"
                    onMouseEnter={() => setHovered2(true)}
                    onMouseLeave={() => setHovered2(false)}
                    style={{
                        backgroundColor: `${caseStudies[nextIndex].color}`,
                        color: `${caseStudies[nextIndex].fontColor}`
                    }}>
                    <p>{caseStudies[nextIndex].title.toUpperCase()}</p>
                    <div>
                        <motion.p
                        className="project__arrow"
                            animate={{ x: isHovered2 ? '100%' : 0, transition: { ease: 'easeInOut', duration: 0.75 } }}
                        >→</motion.p>
                    </div>

                </button>
            </Link>
        </div>
    )
}