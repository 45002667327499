import PictokLightBox from "./PictokLightBox"

export default function PictokInfo() {

    return (
        <div className="pictok__mainContainer">
            <div className="pictok__mainTitle">
                <h2>Structure</h2>
            </div>
            <div className="pictok__mainContent">
                <p>
                    Based on research insights, the team focused on a <strong>"Friends-to-VIPs"</strong> photo-sharing experience. In the "Friends-to-VIPs" experience, VIPs serve as the primary photo recipients, receiving images from friends. Meanwhile, "Friends" - sighted individuals take on the role of the senders, initiating the photo-sharing process. This understanding has facilitated the developement of two personas and the userflow.</p>
                    <PictokLightBox/>
            </div>
            
        </div>

    )
}