
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Trio({ src1, src2, src3, alt1, alt2, alt3 }) {


    return (

        <div className="gallery__box gallery__trio">
            <LazyLoadImage src={src1} alt={alt1} effect="blur" />
            <LazyLoadImage src={src2} alt={alt2} effect="blur" />
            <LazyLoadImage src={src3} alt={alt3} effect="blur" />
        </div>

    );
}
