import Nav from "../../components/UI/Nav"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../components/UI/Transition";
import ProjectFooter from "../../components/UI/Footer/ProjectFooter";
import { motion } from "framer-motion";

export default function INFJ() {

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);

    return (
        <Transition>
            <div
                style={{
                    backgroundColor: '#f3f9f3',
                }}
                className="main__container infj__mainContainer re-padding">
                <div className="menu__fix">
                    <Nav />
                </div>


                <div className="pictok__container">
                    <section className="infj__topContainer topContainer">

                        <video autoPlay loop muted>
                            <source src="/assets/projects/infj/infj-show.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Overview</h2>
                        </div>

                        <div className="pictok__mainContent infj__content">
                            <p>
                                INFJ Secret Place is a WordPress blog designed to establish an online platform for INFJs, one of the rarest MBTI types. INFJ SP aims to provide a space for INFJs to explore, share, and discuss their unique traits and experiences.
                            </p>
                            <Link className="infj__btn" to="https://www.raxron.com/infjsp/home" target="_blank" rel="noopener noreferrer">
                                <button><p>
                                    <strong>Check out the blog ↗</strong></p></button>
                            </Link>
                        </div>
                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Illustration</h2>
                        </div>

                        <div className="pictok__mainContent">
                            <p>
                                To create a harmonious atmosphere, every graphic and poster featured on the blog is self-created. The consistent design elements contribute to a cohesive vibe, making them a key draw for the audience.
                            </p>
                            <motion.div
                                whileInView={{ x: [-100, 0] }}
                                transition={{ duration: 0.25, delay: 0, type: 'spring', stiffness: '200', damping: '40' }}
                                className="g__4">
                                <img src="/assets/projects/infj/infj-r1.png" alt="infj illustration example 1" />
                                <img src="/assets/projects/infj/infj-r2.png" alt="infj illustration example 2" />
                                <img src="/assets/projects/infj/infj-r3.png" alt="infj illustration example 3" />
                                <img src="/assets/projects/infj/infj-r4.png" alt="infj illustration example 4" />
                                <img src="/assets/projects/infj/infj-r5.png" alt="infj illustration example 5" />
                                <img src="/assets/projects/infj/infj-r6.png" alt="infj illustration example 6" />
                                <img src="/assets/projects/infj/infj-r7.png" alt="infj illustration example 7" />
                                <img src="/assets/projects/infj/infj-r8.png" alt="infj illustration example 8" />
                            </motion.div>
                        </div>
                    </section>
                </div>

            </div>

            <ProjectFooter projectName="infj secret place" />
        </Transition>

    )
}