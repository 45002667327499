import Nav from "../../components/UI/Nav"
import CcProducts from "../../components/Projects/CC/ccProduct"
import { useEffect } from "react";
import Transition from "../../components/UI/Transition";
import ProjectFooter from "../../components/UI/Footer/ProjectFooter";
export default function CC() {

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);

    return (
        <Transition>
            <div
                className="main__container cc__mainContainer re-padding">
                <div className="menu__fix">
                    <Nav />
                </div>

                <CcProducts />

            </div>
            <ProjectFooter projectName="creep culture" />
        </Transition>


    )
}