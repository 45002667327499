import { Link } from "react-router-dom"
import { motion } from "framer-motion"
export default function Links() {
    return (
        <>
            <a href="mailto:xia.rong.rachel@gmail.com">
            <motion.img
                   whileHover={{ scale: 1.5 }}
                   transition={{ type: "spring", stiffness: 400, damping: 10 }} src="/assets/bg/email.png" alt="email icon" />
            </a>
            <Link to="https://www.linkedin.com/in/rachelxrax" target="_blank" rel="noopener noreferrer">
                <motion.img
                   whileHover={{ scale: 1.5 }}
                   transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    src="/assets/bg/linked.png" alt="linkedin icon" />
            </Link>
            <Link to="https://github.com/raxron" target="_blank" rel="noopener noreferrer">
            <motion.img
                   whileHover={{ scale: 1.5 }}
                   transition={{ type: "spring", stiffness: 400, damping: 10 }} src="/assets/bg/git.png" alt="github icon" />
            </Link>
        </>
    )
}