
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Duo12({src1,src2,src3,alt1,alt2,alt3}) {


    return (

        <div className="gallery__box gallery__duo12">
            <div className="gallery__duo12__item">
                <LazyLoadImage src={src1} alt={alt1}  effect="blur" />
            </div>

            <div className="gallery__duo12__item2">
                <LazyLoadImage src={src2} alt={alt2} effect="blur"/>
                <LazyLoadImage src={src3} alt={alt3}  effect="blur"/>
            </div>
        </div>

    );
}
