import Nav from "../../components/UI/Nav"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../components/UI/Transition";
import ProjectFooter from "../../components/UI/Footer/ProjectFooter";
export default function MB() {

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);
    return (
        <Transition>
            <div
                style={{
                    backgroundColor: '#dadada',
                }}
                className="main__container mb__mainContainer re-padding">
                <div className="menu__fix">
                    <Nav />
                </div>


                <div className="pictok__container">
                    <section className="infj__topContainer mb__topContainer topContainer">

                        <video autoPlay loop muted>
                            <source src="/assets/projects/mb/mb-show.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Overview</h2>
                        </div>

                        <div className="pictok__mainContent infj__content">
                            <p>
                                Memory Box is a 2D point-and-click game inspired by Rusty Lake, featuring simple puzzles that unravel the lost memories of the main character. The illustrations and music aim to evoke a dark and eerie atmosphere.
                            </p>
                            <Link className="infj__btn mb__btn" to="https://raxron.itch.io/memory-box" target="_blank" rel="noopener noreferrer">
                                <button><p>
                                    <strong>Play the Game ↗</strong></p></button>
                            </Link>
                        </div>
                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Planning</h2>
                        </div>

                        <div className="pictok__mainContent">
                            <p>
                                The difference for me between game design and web design lies in the planning phase. In game design, the whole plan and required assets were generated at the beginning, with minor changes incorporated later. Twinery was used for this project to identify potential breakpoints before proceeding to the actual coding phase.
                            </p>
                            <img src="/assets/projects/mb/mb-plan.png" alt="project plan" className="mb__plan" />

                        </div>

                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Assets</h2>
                        </div>

                        <div className="pictok__mainContent">
                            <p>
                                The game's graphics were created by myself. Many of these visuals intentionally featured broken shadows and lines to evoke an unsettling atmosphere. Additionally, textures such as wood and paper were used to create a blurred boundary between reality and the dream-like ambiance.
                            </p>
                            <div className="g__4">
                                <img src="/assets/projects/mb/mb-asset4.png" alt="memory box illustration example 1 " />
                                <img src="/assets/projects/mb/mb-asset3.png" alt="memory box illustration example 2" />
                                <img src="/assets/projects/mb/mb-asset2.png" alt="memory box illustration example 3" />
                                <img src="/assets/projects/mb/mb-asset1.png" alt="memory box illustration example 4" />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ProjectFooter projectName="memory box"/>
        </Transition>


    )
}