import React, { useState } from "react";


export default function PjCard01() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };


  return (
    <div>
      <div>
        <img
          className="card__flower1 project__hide__s"
          src="/assets/projects/pictok/picktok-flower-2.png"
          alt="orange flower"
          loading="lazy"
          style={{
            position: "absolute",
            marginLeft: "15em",
            marginTop: "18em",
            width: "6em",
            transform: "rotate(25deg)"
          }} />
        <img
          className="card__flower2 project__hide__s"
          src="/assets/projects/pictok/picktok-flower-1.png"
          alt="orange flower2"
          style={{
            position: "absolute",
            marginLeft: "2.5em",
            marginTop: "17.5em",
            width: "3em",
            transform: "rotate(-25deg)"
          }} />
        <img
          className="card__flower3 project__hide__s"
          src="/assets/projects/pictok/picktok-flower-2.png"
          alt="orange flower"
          style={{
            position: "absolute",
            marginLeft: "0.5em",
            marginTop: "19.75em",
            width: "6em",
            transform: "rotate(-28deg)"
          }} />
      </div>
      <div
        onMouseMove={handleMouseMove}
        className="erase__container"
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src="/assets/projects/pictok/pictok-home-d.png"
          alt="pictok app home page dark mode"
          className="erase__back"
          style={{
            width: "20em",
            height: "100%",
          }}
        />
        <div
          className="erase__front"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "20em",
            height: "100%",
            mask: `url(#circleMask)`,
          }}
        />
        <svg style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
          <defs>
            <mask id="circleMask">
              <rect width="20em" height="30em" fill="white" />
              <circle cx={165} cy={position.y - 150} r="80" fill="black" />
            </mask>
          </defs>
          <image
            href="/assets/projects/pictok/pictok-home.png"
            width="20em"
            height="100%"
            mask={`url(#circleMask)`}
          />
        </svg>
      </div>
    </div>
  );
}
