import React from "react";
import "../../../css/App.css";
import "./Lotus.css";

export default function Lotus() {
    return (
        <div className="container">
            <img className="lotus__1" src="/assets/bg/lotus1.svg" alt="left lotus"/>
            <img className="lotus__2" src="/assets/bg/lotus1.svg" alt="right lotus"/>
            <img className="lotus__3" src="/assets/bg/lotus2.svg" alt="right lotus"/>
            <img className="lotus__4" src="/assets/bg/lotus2.svg" alt="right lotus"/>
        </div>

    )
}