import React, { useEffect, useState } from "react";
import PictokToggle from "../PictokToggle";
import PictokColorData from "../../../../../data/PictokColor.json";

export default function PictokColor() {
    const [pictokColor, setPictokColor] = useState([]);
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        setPictokColor(PictokColorData.pictokColors);
    }, [])

    function toggleSwitch() {
        setIsDark(!isDark);
    }

    return (
        
            <div className="pictok__colorContainer">
                <PictokToggle isDark={isDark} toggleSwitch={toggleSwitch} />
                <div className="pictok__colorPalette">
                    {
                        pictokColor.map((c, i) => {
                            return (
                                <div key={i}>
                                    <div
                                        className="pictok__color__box box__size__xs"
                                        style={{
                                            background: isDark ? `${c.dark}` : `${c.light}`
                                        }}></div>
                                    <p className="pictok__color__name font-xs">
                                        <strong>{isDark ? c.darkname : c.name}</strong></p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
    );
}

