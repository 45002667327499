import Nav from "../../components/UI/Nav"
import Lottie from "react-lottie"
import Ani2 from "../../data/gg-ani-2.json"
import Ani1 from "../../data/gg-ani-1.json"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../components/UI/Transition";
import ProjectFooter from "../../components/UI/Footer/ProjectFooter";
import { motion } from "framer-motion"
export default function GG() {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToTop();
    }, []);

    const ani1 = {
        loop: true,
        autoplay: true,
        animationData: Ani1,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const ani2 = {
        loop: true,
        autoplay: true,
        animationData: Ani2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <Transition>
            <div
                style={{
                    backgroundColor: '#F7FEF4'
                }}
                className="main__container gg__mainContainer re-padding">
                <div className="menu__fix">
                    <Nav />
                </div>

                <div className="pictok__container">
                    <section className="topContainer gg__topContainer">
                        <div className="gg__topContainer__item">
                            <motion.img
                                className="gg__top_img1"
                                whileHover={{ y: -20 }}
                                initial={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1,
                                    scale: 0.95,
                                    rotate: [0, 10],
                                    x: [0, 120],
                                    y: [0, 15]
                                }} src="/assets/projects/gg/gg-shop.png" alt="green generation background" />
                            <motion.img
                                className="gg__top_img2"
                                whileHover={{ y: -20 }}
                                src="/assets/projects/gg/gg-quiz.png" alt="green generation background" />
                        </div>
                        <div className="gg__top__context">
                            <h1>Green Generation</h1>
                            <div className="gg__summary">
                                <div>
                                    <h3>Platform</h3>
                                    <p>Mobile App</p>
                                </div>
                                
                                <div>
                                    <h3>My Role</h3>
                                    <p>Front End</p>
                                </div>

                            </div>
                            <Link className="infj__btn gg__topBtn" to="https://github.com/Sebin-Leeee/term2-big-project/tree/main" target="_blank" rel="noopener noreferrer">
                                <button><p>
                                    <strong>Code on GitHub  ↗</strong></p></button>
                            </Link>
                            <p>
                                An eco-educational quiz app designed for teenagers to enhance their understanding of carbon footprints.</p>
                        </div>
                    </section>

                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Overview</h2>
                        </div>

                        <div className="pictok__mainContent infj__content">
                            <p>
                                Understanding the influence of a carbon footprint is the goal of Green Generation, a friendly and intuitive application that creates a foundation of how the world is affected by young generation's actions.
                            </p>
                            <Link className="infj__btn gg__btn" to="https://term2-big-project.vercel.app/" target="_blank" rel="noopener noreferrer">
                                <button><p>
                                    <strong>Try Out the App ↗</strong></p></button>
                            </Link>
                        </div>
                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>UX Testing</h2>
                        </div>

                        <div className="pictok__mainContent">
                            <p>
                                The most pressing issue identified during the usability testing was the presence of too many technical terms, with a lack of patience from participants to read through them.
                                In response, the team addressed the problem by eliminating numerous complex terminologies and incorporating graphics to facilitate learning. Additionally, two characters were created to aid learning and quiz.
                            </p>
                            <div>
                                <div className="gg__mascot__item">
                                    <img src="/assets/projects/gg/gg-character2.png" alt="green generation character Greenie"></img>
                                    <div className="gg__mascot__talk">
                                        <p>Hello, I'm Greenie, your friend in Green Generation.</p>
                                    </div>
                                </div>
                                <div className="gg__mascot__item">
                                    <img src="/assets/projects/gg/gg-character1.png" alt="green generation character enermy"></img>
                                    <div className="gg__mascot__talk"> 
                                        <p>Hello, I'm Roboto. I will challenge you with questions.</p>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </section>
                    <section className="pictok__mainContainer">
                        <div className="pictok__mainTitle">
                            <h2>Interactions</h2>
                        </div>

                        <div className="pictok__mainContent">
                            <p>
                                Interactions are crucial for the Green Generation app, because of the target audience of teenagers. To gamify the experience, the team introduced Greenie, the mascot, and developed various animations featuring Greenie as rewards for completing different tasks, such as obtaining a perfect score on a quiz. Here are some examples of animation and graphics I designed and implemented using <strong>After Effects</strong> and <strong>Lotties</strong>:
                            </p>
                            <div className="gg__anim">
                                <img src="/assets/projects/gg/gg-3.png" alt="green generation mascor"
                                    width={110} />


                                <Lottie
                                    options={ani2}
                                    height={200}
                                    width={150}
                                />
                                <Lottie
                                    options={ani1}
                                    height={200}
                                    width={180}

                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ProjectFooter projectName="green generation" />
        </Transition>



    )
}


