import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"

export default function ArrowUp() {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;


    setIsVisible(scrollY > window.innerHeight * 0.05);
  };

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (

    <div
      style={{ opacity: isVisible ? 1 : 0 }}
      className="design__arrowUp" onClick={scrollToTop}>
      <motion.p
        data-content="↑"
        initial={{ y: 0 }}
        whileHover={{ y: '-100%', transition: { ease: 'easeInOut', duration: 0.75, repeat: Infinity } }}
      >
        ↑
      </motion.p>
    </div>




  );
}
