import React, { useState, useEffect } from "react";
import ccProductData from "../../../../data/ccProduct.json";
import { motion, AnimatePresence } from 'framer-motion';

export default function CcProducts() {
    const [products, setProducts] = useState([]);
    const [flavor, setFlavor] = useState("pumpkin");

    const updateFlavor = (newFlavor) => {
        setFlavor(newFlavor);
    };

    useEffect(() => {
        setProducts(ccProductData.products);

        ccProductData.products.forEach((product) => {
            const img = new Image();
            img.src = product.url;
        });
    }, []);

    const filteredProducts = products.filter(p => p.name === flavor);

    const variants = {
        initial: {
            x: 150,
            opacity: 0
        },
        animate: {
            x: 0,
            opacity: 1
        },
        exit: {
            x: -150,
            opacity: 0
        },
        transition: {
            duration: 5,
        }
    };

    return (
        <div style={{marginTop:'3em'}}>
            {filteredProducts.map((product, index) => (
                <AnimatePresence key={index}>
                    <div key={index}>
                        <div
                            style={{
                                color: `${product.font}`,
                                backgroundColor: `${product.color}`
                            }}
                            className="cc__topContainer">

                            <motion.div
                                className="cc__carousel"
                                key={product.name} variants={variants} animate='animate' initial='initial' exit='exit' transition='transition' >
                                <img
                                    className="cc__deco__1" src={product.deco} alt="real pumpkin" />
                                <img
                                    className="cc__deco__2" src={product.deco} alt="real pumpkin" />
                                <motion.h1

                                    key={product.name}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                                >Creep<span className="cc__name__span"></span>Culture</motion.h1>
                                <img
                                    className="cc__product"
                                    src={`${product.url}`} alt={product.alt} />
                            </motion.div>

                            <div className="cc__choose">
                                <h2 style={{
                                    color: `${product.font}`
                                }}>Kombucha Flavor</h2>
                                <ul>
                                    <li
                                        style={{
                                            border: `0.15em solid ${product.font}`,
                                            backgroundColor: flavor === 'pumpkin' ? 'rgba(250,250,250,0.5)' : ''
                                        }}
                                        onClick={() => updateFlavor("pumpkin")}
                                    >
                                        Pumpkin
                                    </li>
                                    <li
                                        style={{
                                            border: `0.15em solid ${product.font}`,
                                            backgroundColor: flavor === 'appleCider' ? 'rgba(0,0,0,0.5)' : ''
                                        }}
                                        onClick={() => updateFlavor("appleCider")}>Apple</li>
                                    <li
                                        style={{
                                            border: `0.15em solid ${product.font}`,
                                            backgroundColor: flavor === 'caramel' ? 'rgba(250,250,250,0.5)' : ''
                                        }}
                                        onClick={() => updateFlavor("caramel")}>Caramel</li>

                                </ul>
                            </div>
                        </div>
                        <div className="pictok__mainContainer">
                            <div className="pictok__mainTitle">
                                <h2>Overview</h2>
                            </div>
                            <div className="pictok__mainContent">
                                <p>
                                    Creep Culture is a faux Kombucha company based in BC, Canada. With Halloween approaching, the 'Halloween Limited' line has been unveiled for the holiday.
                                    The package design draws inspiration form the Halloween costume tradition, featuring distinct characters that represent the flavors:
                                </p>
                                <div className="cc__choose cc__choose__2">
                                    <ul>
                                        <li
                                            style={{
                                                border: `0.15em solid var(--color--cc--dark)`,
                                                backgroundColor: flavor === 'pumpkin' ? 'var(--color--cc--orange)' : ''
                                            }}
                                            onClick={() => updateFlavor("pumpkin")}
                                        >
                                            Pumpkin
                                        </li>
                                        <li
                                            style={{
                                                border: `0.15em solid var(--color--cc--dark)`,
                                                backgroundColor: flavor === 'appleCider' ? 'var(--color--cc--apple)' : ''
                                            }}
                                            onClick={() => updateFlavor("appleCider")}>Apple</li>
                                        <li
                                            style={{
                                                border: `0.15em solid var(--color--cc--dark)`,
                                                backgroundColor: flavor === 'caramel' ? 'var(--color--cc--caramel)' : ''
                                            }}
                                            onClick={() => updateFlavor("caramel")}>Caramel</li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="pictok__mainContainer cc__mainContainer__lessMargin"  >
                            <div className="pictok__mainTitle">
                                <h2>Character</h2>
                            </div>

                            <motion.div
                                key={product.name}
                                animate={{ opacity: [0, 1] }}
                                transition={{ duration: 1.5 }}
                                className="cc__process">
                                <img src={`/assets/projects/cc/cc-${product.name}-process1.png`} alt="draw initial" />
                                <img src={`/assets/projects/cc/cc-${product.name}-process2.png`} alt="draw with shadow" />
                                <img src={`/assets/projects/cc/cc-${product.name}-process3.png`} alt="draw final" />
                            </motion.div>
                        </div>
                        <div className="pictok__mainContainer">
                            <div className="pictok__mainTitle">
                                <h2>Package</h2>
                            </div>
                            <motion.div
                                key={product.name}
                                animate={{ opacity: [0, 1] }}
                                transition={{ duration: 1.5 }}
                                className="cc__package">
                                <img src={`/assets/projects/cc/cc-${product.name}-full.png`} alt="draw initial" />
                            </motion.div>
                        </div>
                    </div>
                </AnimatePresence>



            ))}


        </div>
    );
}
