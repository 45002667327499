

export default function PictokChallenge() {
    return (
        <div className="pictok__mainContainer">
            <div className="pictok__mainTitle">
                <h2>Challenges</h2>
            </div>
            <div className="pictok__mainContent">
                <p className="pictok__summary__content">
                The PicTok team faced various challenges due to the unique nature of the topic and the absence of comparable products in the market. Determining what qualifies as good or bad is particularly challenging. The primary issues confronted by the PicTok team include the absence of a baseline for comparing designs and the difficulty in deciding which functions to implement.</p>
                <div className="pictok__subContent">
                    <div>
                        <h3>Design Revisions</h3>
                        <p>The design team produced multiple revisions, particularly focusing on the home page. The initial home page featured four buttons but with a different layout.</p>
                        <p>The PicTok team exventually decided to place the four buttons in the top left, top right, bottom left, and bottom right. This strategic placement ensures ease of access for VIPs, whether they hold the device horizontally or vertically.</p>
                    </div>
                    <img className="pictok__homes" src="/assets/projects/pictok/pictok-homes.png" alt="pictok old and new home pages compare" />
                </div>
                <div className="pictok__subContent">
                    <div>
                        <h3>Development Decisions</h3>
                        <p>Due to resource limitations and time constraints, certain designed pages, such as profile pages and user registration, could not be delivered, and some conceptual ideas had to be canceled. Instead, the team decided to focus its efforts on refining the inbox and camera features.</p>
                        <p>Another challenge encountered was the initial long waiting period during the image-to-sound conversion. While the original plan was to allow senders to minimize the app to the background. The features was deleted because the development team successfully reduced the conversion period to less than 15 seconds. </p>
                        <p>Several envisioned concepts, including the idea of letting senders add their voices or choose from various sound effects (rain, birds, rustling trees), similar to choose photo filters, were not implemented in this iteration. The team looks forward to exploring and developing these features in future iterations.</p>
                    </div>
                    <img className="pictok__homes" src="/assets/projects/pictok/pictok-otherpages.png" alt="pictok friend's profile and user registration design" />
                </div>


            </div>

        </div>

    )
}