import React from "react";
import { Link, NavLink } from "react-router-dom";

import { motion } from "framer-motion";


const Hambuger = ({ isMenuActive, classNameFunc }) => {


    const ballVariants = {
        float: {
            y: ["0em", "-20em", "-10em", "-5em", "-15em"],
            x: ["0em", "15em", "30em"],
            opacity: [0, 0.4, 1, 0.5, 0],
            transition: {
                repeat: Infinity,
                duration: 30,
                ease: "easeInOut",
                repeatType: 'reverse'

            },
        },
        opacity: {
            y: ["0em", "-10em", "2em", "-5em", "-15em"],
            x: ["0em", "5em", "10em", "30em"],
            opacity: [0, 0.6, 1, 0.5, 0],
            transition: {
                repeat: Infinity,
                duration: 30,
                ease: "easeInOut"

            },
        },
    };
    const variants = {
        open: {
            x: 0,
            borderRadius: "0%",
            width: "100%",
            height: "100vh",
            opacity: 1,
            transition: {
                duration: 5,
                delay: 0.5,
                type: "spring",
                stiffness: 400,
                damping: 40,
            },
        },
        close: {
            x: 200,
            borderRadius: "20%",
            width: "0%",
            height: "0%",
            opacity: 0,
            transition: {
                delay: 1,
                duration: 15,
                type: "spring",
                stiffness: 300,
                damping: 40,
            },
        },
    };

    const list = {
        open: {
            x: 0,
            opacity: 1,
            filter: "blur(0px)",
            transition: {
                delay: 0.75,
                duration: 0.75,
            },
        },
        close: {
            x: 200,
            opacity: 0,
            filter: "blur(20px)",
            transition: {
                delay: 0.5,
                duration: 1,
            },
        },
    };

    return (
        <motion.div
            variants={variants}
            animate={isMenuActive ? "open" : "close"}
            className="nav__hambuger__container" >
           
            <div className="nav__hambuger__content">
                <motion.div
                    variants={list}
                    animate={isMenuActive ? "open" : "close"}
                    className="nav__hambuger__list">
                    <NavLink
                        className={classNameFunc} to="/"><p>Projects</p></NavLink>
                    <NavLink
                        className={classNameFunc} to="/designs"><p>Designs</p></NavLink>
                    <NavLink
                        className={classNameFunc} to="/contact"><p>Contact</p></NavLink>
                </motion.div>
                <motion.div
                    variants={list}
                    animate={isMenuActive ? "open" : "close"}
                    className="nav__hambuger__contact">
                    <Link to="/contact"> <p>Rachel Xia</p></Link>
                    <Link to="https://www.linkedin.com/in/rachelxrax" target="_blank" rel="noopener noreferrer">
                        <p>Linkedin: @Rachel Xia</p></Link>
                    <a href="mailto:xia.rong.rachel@gmail.com">
                        <p>Xia.rong.rachel@gmail.com</p></a>

                </motion.div>
                <div
                    style={{ opacity: isMenuActive ? 0.5 : 0, transition: 'all ease-in-out 500ms' }}
                    className="nav__hambuger__koi">
                    <img src="/assets/bg/koi.svg" width={80} alt="koi" />
                </div>
                <div className="nav__hambuger__ani">
                    <motion.div
                        className="nav__menu__balls"
                        variants={ballVariants}
                        animate="float"
                    >
                        <span></span>
                    </motion.div>
                    <motion.div
                        className="nav__menu__balls"
                        variants={ballVariants}
                        animate="opacity"
                    >
                        <span></span>
                    </motion.div>
                </div>

            </div>

        </motion.div>
    );
};

export default Hambuger;
