import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Duo11({src1,src2,alt1,alt2}) {


    return (

        <div className="gallery__box gallery__duo11">
           
                <LazyLoadImage src={src1} alt={alt1}  effect="blur"/>
            

           
                <LazyLoadImage src={src2} alt={alt2}  effect="blur"/>
            
        </div>

    );
}
