import React from "react";
import PjCard01 from "../components/Projects/PjCard/PjCard01";
import PjCard02 from "../components/Projects/PjCard/PjCard02";
import PjCard03 from "../components/Projects/PjCard/PjCard03";
import PjCard04 from "../components/Projects/PjCard/PjCard04";
import PjCard05 from "../components/Projects/PjCard/Pjcard05";

const renderCard = (activeIndex) => {
    switch (activeIndex) {
        case 'PjCard01':
            return (
                    <PjCard01 />            
            );
        case 'PjCard02':
            return (
                    <PjCard02 />           
            );
        case 'PjCard03':
            return (          
                    <PjCard03 />         
            );
        case 'PjCard04':
            return (     
                  <PjCard04 />            
            );
        case 'PjCard05':
            return(
                <PjCard05/>
            )
        default:
            return null;
    }
};

export default renderCard;
