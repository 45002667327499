
import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function PictokLightBox() {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0)

    return (
        <div className="pictok__lightbox">
            <div className="pictok__subContent">
                <div>
                    <h3>Dual Personas for Essential Elements</h3>
                    <p>In the "Friends-to-VIP" experience, the primary persona was created for VIPs and the secondary persona helped discern essential elements for the minimal viable product (MVP) within the time constraints.</p>
                    <p>The team were able to highlit the essential feartues efficiently: simple navigation, compatibility with/or inclusive screen readers, avoid too much scrolling on one page and high contrast text and buttons.</p>
                </div>
            </div>
            <div className="pictok__persona__container"> 
                <div >
                    <img
                        className="pictok__persona"
                        onClick={() => { setOpen(true); setIndex(1); }}
                        src="/assets/projects/pictok/pictok-persona-1.png"
                        alt="pictok primary user persona"
                    />
                    <p className="pictok__lightbox__notes font-xs">
                        <strong>
                            Primary Persona
                        </strong>
                    </p>
                </div>
                <div>
                    <img
                        className="pictok__persona__1"
                        onClick={() => { setOpen(true); setIndex(2); }}
                        src="/assets/projects/pictok/pictok-persona-2.png"
                        alt="pictok secondary user persona"
                    />
                    <p className="pictok__lightbox__notes font-xs">
                        <strong>
                            Secondary Persona
                        </strong>
                    </p>
                </div>
            </div>



            <div className="pictok__subContent">
                <div>
                    <h3>Simple Journey for Optimal Results</h3>
                    <p> In response to the needs of VIPs and incorporating preferences from sighted individuals, the design priority are: Home, Inbox and Camera pages.
                        Tutorials, Friends, the landing page, and user registration are the secondary concerns.</p>
                </div>

            </div>

            <div>
                <img
                    className="pictok__userflow"
                    style={{
                        width: '100%',
                        height: 'auto'
                    }}
                    onClick={() => { setOpen(true); setIndex(0); }}
                    src="/assets/projects/pictok/pictok-userflow.png"
                    alt="pictok userflow" />

                <p className="pictok__lightbox__notes font-xs">
                    <strong>
                        User Flow
                    </strong>
                </p>
            </div>

            <Lightbox
                styles={{
                    container: { backgroundColor: "rgba(25,157, 252, .8)" }
                  }}
                open={open}
                index={index}
                close={() => setOpen(false)}
                slides={[
                    { src: "/assets/projects/pictok/pictok-userflow.png" },
                    { src: "/assets/projects/pictok/pictok-persona-1.png" },
                    { src: "/assets/projects/pictok/pictok-persona-2.png" },
                ]}
            />
        </div>

    )
}