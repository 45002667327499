import { motion } from "framer-motion"
import anim from "../../../utils/anim"
import TransitionSvg from "./TransitionSvg"
import { useEffect, useState } from "react"
import "./Transition.css"

export default function Transition({ children }) {

    const [dimension, setDimension] = useState({
        width: 0,
        height: 0
    })

    useEffect(() => {
        const resize = () => {
            setDimension({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        resize();
        window.addEventListener("resize", resize)
        return () => window.removeEventListener("resize", resize)
    }, [])


    const opacity = {
        initial: {
            opacity: 0
        },
        enter: {
            opacity: 1,
            transition: {
                delay: 0.2,
                duration: 1
            }
        },
        exit: {
            opacity: 1
        }
    }
    return (

        <motion.div  {...anim(opacity)} className="transition__container">
          
            <div className="transition__background">
                {dimension.width > 0 &&
                    <TransitionSvg {...dimension} />}
            </div>
            <motion.div > {children}</motion.div>
        </motion.div>


    )
}