const downAnim = (delay) => {
    return {
        duration:1,
        opacity:[0,1],
        y:[-70,0],
        transition:{duration: 2, delay: delay, type: 'spring', stiffness: '200', damping: '40' }
    }
}

export default downAnim;
