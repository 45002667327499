import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({ classNameFunc }) => {

    return (

        <div className="nav__item">
            <ul>
                <li>
                    <NavLink
                        to="/"
                        className={classNameFunc}>
                        <div className="balls nav__balls"><span></span></div>Projects</NavLink>
                </li>
                <li>
                    <NavLink to="/designs"
                        className={classNameFunc}>
                        <div className="balls nav__balls"><span></span></div>Designs</NavLink>
                </li>
                <li>
                    <NavLink to="/contact"
                        className={classNameFunc}>
                        <div className="balls nav__balls"><span></span></div>Contact</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default NavItem;